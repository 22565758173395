import { useAuthType } from '@endaoment-frontend/authentication';
import { getChainNameForChainId, getTransactionLink } from '@endaoment-frontend/multichain';
import { ChainIcon, InfoIcon, PrivateActivityIcon, ReceiptIcon } from '@endaoment-frontend/ui/icons';
import { Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatDate } from '@endaoment-frontend/utils';

import styles from './ActivityRightItems.module.scss';
import type { ActivitySubject, UserActivity } from './types';

type ActivityRightItemsProps = {
  activity: UserActivity;
  subject?: ActivitySubject;
  onClickReceipt: ((activity: UserActivity) => void) | undefined;
};

export const ActivityRightItems = ({ activity, subject, onClickReceipt }: ActivityRightItemsProps) => {
  const { isSocialAuth } = useAuthType();

  const isIncompletePledge =
    'outcome' in activity && (activity.outcome === 'AwaitingAssets' || activity.outcome === 'Pending');
  const isReceiptableActivityType =
    activity.type !== 'transaction' && activity.type !== 'custom' && activity.type !== 'portfolio_trade';

  return (
    <div>
      {'automated' in activity && !!activity.automated && (
        <Tooltip
          content='This activity was automatically generated by Endaoment'
          as='span'
          className={styles['automated-tooltip']}>
          Automated
          <InfoIcon color='currentColor' width='14px' />
        </Tooltip>
      )}
      {(subject === 'fund' || subject === 'org') && !!isIncompletePledge && (
        <Tooltip content='This pledge is only viewable by you until the assets are received.'>
          <PrivateActivityIcon />
        </Tooltip>
      )}
      {!!onClickReceipt &&
        !!isReceiptableActivityType &&
        !!(
          subject === 'user' ||
          // Do not show incomplete pledges for featured activities
          (subject !== 'featured' && isIncompletePledge)
        ) && (
          <div
            onClick={() => onClickReceipt(activity)}
            className={styles['receipt-button']}
            role='button'
            title='Receipt'>
            <Tooltip content='View receipt'>
              <ReceiptIcon />
            </Tooltip>
          </div>
        )}
      {'chainId' in activity && !!activity.chainId && !!activity.transactionHash && !isSocialAuth && (
        <a
          target='_blank'
          href={getTransactionLink(activity.transactionHash, activity.chainId)}
          rel='noreferrer'
          className={styles['chain-link']}>
          <Tooltip
            content={`This activity occurred on ${getChainNameForChainId(
              activity.chainId,
            )}. Click to view in explorer.`}
            placement='left'>
            <ChainIcon chainId={activity.chainId} filled light />
          </Tooltip>
        </a>
      )}
      <Pill
        className={styles['date-pill']}
        size='tiny'
        title={formatDate(activity.occurredAtUtc, { dateStyle: 'long' })}>
        {formatDate(activity.occurredAtUtc, { dateStyle: 'short' })}
      </Pill>
    </div>
  );
};
