import { match, P } from 'ts-pattern';
import { formatUnits, type Address } from 'viem';

import type { UserActivity } from '@endaoment-frontend/activities';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import type { EntityLabel } from '@endaoment-frontend/types';
import { formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

type ExportableActivity = {
  date: string;
  operation: 'Donation' | 'Grant' | 'Operation' | 'Portfolio Buy' | 'Portfolio Sell';
  status: 'Completed' | 'Pending';
  destination: string;
  usd: string;
  asset?: string;
  assetAmount?: string;
  transactionUrl?: string | null;
};

const formatDestination = (to: EntityLabel): string => (to.type === 'fund' ? 'DAF:' : 'Org:') + ` ${to.name}`;
const formatTransactionUrl = (transactionHash?: Address | null, chainId?: number): string =>
  transactionHash && chainId ? getTransactionLink(transactionHash, chainId) : '';

export const getActivitiesToExport = (activities: Array<UserActivity>): Array<ExportableActivity> => {
  const exportableOrNull = activities.map(activity => {
    const date = formatDate(activity.occurredAtUtc, { dateStyle: 'short' });
    if ('outcome' in activity && activity.outcome === 'Failure') return null;
    return match(activity)
      .returnType<ExportableActivity | null>()
      .with({ type: 'donation' }, activity => ({
        date,
        operation: 'Donation',
        status: 'Completed',
        destination: formatDestination(activity.to),
        usd: formatCurrency(formatUsdc(activity.usdcAmount)),
        asset: activity.token.symbol,
        assetAmount: formatUnits(activity.amount, activity.token.decimals),
        transactionUrl: formatTransactionUrl(activity.transactionHash, activity.chainId),
      }))
      .with({ type: P.union('grant', 'internal_transfer') }, activity => ({
        date,
        operation: 'Grant',
        status: 'Completed',
        destination: formatDestination(activity.to),
        usd: formatCurrency(formatUsdc(activity.amount)),
        asset: '',
        assetAmount: '',
        transactionUrl: formatTransactionUrl(activity.transactionHash, activity.chainId),
      }))
      .with({ type: 'portfolio_trade' }, activity => ({
        date,
        operation: `Portfolio ${activity.tradeType}`,
        status: activity.outcome === 'Completed' ? 'Completed' : 'Pending',
        destination: `${activity.portfolio.name} Portfolio`,
        usd: formatCurrency(formatUsdc(activity.usdcAmount)),
        asset: activity.portfolio.ticker || '',
        assetAmount: '',
        transactionUrl: formatTransactionUrl(activity.transactionHash, activity.chainId),
      }))
      .with({ type: 'fiat_donation_pledge' }, activity => ({
        date,
        operation: 'Donation',
        status: activity.outcome === 'Success' ? 'Completed' : 'Pending',
        destination: formatDestination(activity.to),
        usd: formatCurrency(formatUsdc(activity.usdcAmount)),
        asset: '',
        assetAmount: '',
        transactionUrl: '',
      }))
      .with({ type: 'stock_donation_pledge' }, activity => ({
        date,
        operation: 'Donation',
        status: activity.outcome === 'Success' ? 'Completed' : 'Pending',
        destination: formatDestination(activity.to),
        usd: formatCurrency(formatUsdc(activity.usdcAmount)),
        asset: activity.stock.ticker,
        assetAmount: '',
        transactionUrl: '',
      }))
      .with({ type: 'nec_donation_pledge' }, activity => ({
        date,
        operation: 'Donation',
        status: activity.outcome === 'Success' ? 'Completed' : 'Pending',
        destination: formatDestination(activity.to),
        usd: '',
        asset: activity.token.symbol,
        assetAmount: formatUnits(activity.amount, activity.token.decimals),
        transactionUrl: '',
      }))
      .with({ type: 'migration_donation_pledge' }, activity => ({
        date,
        operation: 'Donation',
        status: activity.outcome === 'Success' ? 'Completed' : 'Pending',
        destination: formatDestination(activity.to),
        usd: formatCurrency(formatUsdc(activity.usdcAmount)),
        asset: '',
        assetAmount: '',
        transactionUrl: '',
      }))
      .with({ type: P.union('custom', 'transaction', 'reward') }, () => null)
      .exhaustive();
  });
  return exportableOrNull.filter((exportable): exportable is NonNullable<typeof exportable> => exportable !== null);
};
